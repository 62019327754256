import { useQuery } from '@tanstack/react-query';
import {
  MerchantPreviewsOptions,
  MerchantRoleFetchResponse,
  MerchantUserFetchResponse,
  MerchantUserPreviewsOptions,
  Users,
} from '../../types/users';
import { POST, TApiResponse } from '../api-service';
import { QUERY_APPLICATION, QUERY_MERCHANT } from '../../utils/config';

export const getMerchantListList = (
  options: MerchantPreviewsOptions = {}
): TApiResponse<MerchantRoleFetchResponse> => {
  return POST({
    URL: 'merchantrolelist',
    body: {
      ...options,
      version: 3,
      action: 'read',
    },
  });
};

export const getMerchantUserListList = (
  options: MerchantUserPreviewsOptions = {}
): TApiResponse<MerchantUserFetchResponse> => {
  return POST({
    URL: 'merchantuserlist',
    body: {
      ...options,
      version: 3,
      action: 'read',
      command: 'merchantuserlist',
    },
  });
};

export const useFetchMerchantUserList = (
  options?: Parameters<typeof getMerchantUserListList>[0]
) =>
  useQuery({
    queryKey: ['getmerchantuserlist'],
    queryFn: () =>
      getMerchantUserListList({
        query_merchant: QUERY_MERCHANT,
        query_application: QUERY_APPLICATION,
        role: localStorage.getItem('roleId') as string,
        ...options,
      }),
    enabled: true,
  });

export const getUserInfo = (options: {
  role: string;
  access_token?: string;
}): TApiResponse<{
  user: Users;
  status: string;
}> => {
  return POST({
    URL: 'userinfo',
    body: {
      ...options,
      command: 'userinfo',
      version: 3,
      action: 'read',
    },
  });
};

export const getProfileInfo = (options: {
  role: string;
}): TApiResponse<{
  user: Users;
  status: string;
}> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  return POST({
    URL: 'userget',
    body: {
      ...options,
      id: user?._id,
      // id: '67518dd7f39e9ee0b1221d11',
      command: 'userget',
      version: 3,
      action: 'read',
    },
  });
};

export const updateProfileInfo = (
  options: any
): TApiResponse<{
  user: Users;
  status: string;
}> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  return POST({
    URL: 'userupdateprofile',
    body: {
      ...options,
      id: user?._id,
      role: localStorage.getItem('roleId'),
      // id: '67518dd7f39e9ee0b1221d11',
      command: 'userupdateprofile',
      version: 3,
      action: 'update',
    },
  });
};
