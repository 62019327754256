import { Icon } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashCan,
  faCircleCheck,
  faEye,
  faEyeSlash,
  faPersonBooth,
  faUserTimes,
  faPen,
  faEnvelopesBulk,
} from '@fortawesome/free-solid-svg-icons';

export function TrashCan() {
  // return <Icon as={() => <FontAwesomeIcon icon={faTrashCan} />} />;
  return <Icon as={FontAwesomeIcon} icon={faTrashCan} />;
}

export function Edit() {
  return <Icon as={() => <FontAwesomeIcon icon={faPen} />} />;
}

export function CircleCheck() {
  return <Icon as={() => <FontAwesomeIcon icon={faCircleCheck} />} />;
}

export function View() {
  return <Icon as={() => <FontAwesomeIcon icon={faEye} />} />;
}
export function HideEye() {
  return <Icon as={() => <FontAwesomeIcon icon={faEyeSlash} />} />;
}

export function Impersonate() {
  return <Icon as={() => <FontAwesomeIcon icon={faPersonBooth} />} />;
}

export function ExitImpersonate() {
  return <Icon as={() => <FontAwesomeIcon icon={faUserTimes} />} />;
}

export function EnvelopesBulk() {
  return <Icon as={() => <FontAwesomeIcon icon={faEnvelopesBulk} />} />;
}
